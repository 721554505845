<template>
    <div>
        <el-row style="display: flex; align-items: center;">
            <el-col :span="8" class="component-title" style="cursor: pointer;" v-if="!isMobile()">
                Course Search
            </el-col>
            <el-col v-else>
                &nbsp;
            </el-col>
            <el-col :offset="14" :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row style="background-color: #7d90d0; padding: 1%;">
            <el-col :span="8" style="padding: 0 1vw;">
                <el-autocomplete
                        @keyup.native.enter.stop="onSubmit"
                        @select="onSubmit"
                        placeholder="Select by Course or by Course Code"
                        type="text"
                        v-model="course"
                        class="el-input form-search-bar"
                        :fetch-suggestions="autoCompleteCourse"
                ></el-autocomplete>
            </el-col>
            <el-col :span="8" style="padding: 0 1vw;">
                <el-autocomplete
                        @keyup.native.enter.stop="onSubmit"
                        @select="onSubmit"
                        placeholder="Select by College"
                        type="text"
                        v-model="college"
                        class="el-input form-search-bar"
                        :fetch-suggestions="autoCompleteCollege"
                ></el-autocomplete>
            </el-col>
            <el-col :span="3" style="padding: 0 1vw;">
                <el-button style="width: 100%;" @click="onSubmit">
                    Search
                </el-button>
            </el-col>
        </el-row>
        <el-row v-if="getSelectedCoursesData.length > 0" style="margin-top: 2vh;">
            <data-tables :data="getSelectedCoursesData" :page-size="5"
                         style="height: 80vh; overflow-y: auto;  width: 100%;"
                         :pagination-props="{ pageSizes: [5, 10] }">
                <el-table-column fixed label="Code" :prop="'id'" width="120"
                                 :sortable="true"></el-table-column>
                <el-table-column fixed label="Code" :prop="'code'" width="120"
                                 :sortable="true"></el-table-column>
                <el-table-column label="Title" :prop="'title'" width="300" :sortable="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.title"/>
                    </template>
                </el-table-column>
                <el-table-column label="CAO Points" :prop="'cao_points'" width="150"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.cao_points"/>
                    </template>
                </el-table-column>
                <el-table-column label="Bio" :prop="'bio'" width="300"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input type="textarea" :rows="4" v-model="scope.row.bio"/>
                    </template>
                </el-table-column>
                <el-table-column label="Qualification" :prop="'qualification'" width="300"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.qualification"/>
                    </template>
                </el-table-column>
                <el-table-column label="Duration" :prop="'duration'" width="250"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.duration"/>
                    </template>
                </el-table-column>
                <el-table-column label="College Name" :prop="'college_name'" width="200"
                                 :sortable="true"></el-table-column>
                <el-table-column label="Email" :prop="'email'" width="250"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.email"/>
                    </template>
                </el-table-column>
                <el-table-column label="Phone" :prop="'phone'" width="250"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.phone"/>
                    </template>
                </el-table-column>
                <el-table-column label="YT URL" :prop="'vidurl'" width="250"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.vidurl"/>
                    </template>
                </el-table-column>
                <el-table-column label="Requirement Desc" :prop="'requirementDesc'" width="250"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input type="textarea" :rows="4" v-model="scope.row.requirementDesc"/>
                    </template>
                </el-table-column>
                <el-table-column label="Requirements" :prop="'requirements'" width="250"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input type="textarea" :rows="4" v-model="scope.row.requirements"/>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="Operations"
                        width="120">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="editRow(scope.$index, scope.row)">
                            Save
                        </el-button>
                    </template>
                </el-table-column>
            </data-tables>
        </el-row>
    </div>
</template>

<script>
  import ProfileAvatar from '../../main_components/ProfileAvatar';

  export default {
    name: 'ViewEditCourseInformation',
    components: {ProfileAvatar},
    data: function() {
      return {
        loadingScreen: false,
        coursesArr: [],
        collegesArr: [],
        course: '',
        college: '',
      };
    },
    mounted() {
      let self = this;
      self.$store.state.loadingScreen = true;
      self.checkUserLoggedIn().then(response => {
        response;
        self.fetchFiltersList().then(response => {
          self.coursesArr = response['courses'];
          self.collegesArr = response['colleges'];
          self.$store.state.loadingScreen = false;
        }).catch(error => {
          self.coursesArr = error;
          self.$store.state.loadingScreen = false;
        });
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    computed: {
      getSelectedCoursesData: function() {
        return this.$store.state.searchedCourses;
      },
    },
    methods: {
      editRow: function(index, row) {
        let self = this;
        let url = `${self.getBaseUrl()}/keywords/courses/update/${row.id}/`;
        $.ajax({
          url: url,
          type: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify(row),
          beforeSend: function(request) {
            if (self.getAuthToken()) {
              request.setRequestHeader(
                  'Authorization',
                  'Token ' + self.getAuthToken(),
              );
            }
          },
          success: function(response) {
            self.$message({
              title: 'Successful',
              message: 'Course data successfully updated',
              type: 'success',
              duration: 3000,
              showClose: true,
            });
          },
          error: function(error) {
            console.log(error);
            self.$message({
              title: 'Unsuccessful',
              message: 'Update failed',
              type: 'error',
              duration: 3000,
              showClose: true,
            });
          },
        });
      },
      autoCompleteCourse: function(queryString, cb) {
        let programList = [];
        programList = this.coursesArr.map(function(element) {
          // if (programList.includes({value: element.title}))
          return {value: element};
        });
        this.querySearchAsync(programList, queryString, cb);
      },
      autoCompleteCollege: function(queryString, cb) {
        let programList = [];
        programList = this.collegesArr.map(function(element) {
          return {value: element};
        });
        this.querySearchAsync(programList, queryString, cb);
      },
      querySearchAsync(programList, queryString, cb) {
        //Add a value field for each object in this array, because autocomplete only recognizes the value field and displays it in the drop-down column, so I have repackaged it.
        let results = queryString
            ? programList.filter(this.createFilter(queryString))
            : programList;
        //The basic idea is to implement a filter that filters all the data that exists in your input field.
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 1000 * Math.random());
      },
      createFilter(queryString) {
        return (program) => {
          return (
              program.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
          );
        };
      },
      onSubmit() {
        let requestQuery = [];
        if (this.course !== '')
          requestQuery.push('course=' + this.course);
        if (this.college !== '')
          requestQuery.push('college=' + this.college);
        requestQuery = requestQuery.join('&');
        this.getCourseDataBackend(requestQuery).then(response => {
        }).catch(error => {
          console.log(error);
        });
      },
    },
  };
</script>

<style scoped>

</style>
